<template>
  <el-dialog
    class="purchaseBox"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="900px"
    :append-to-body="true"
    :show-close="false"
    :custom-class="'purchaseWrapper'"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    @open="onOpenDialog"
    @closed="handleClose"
    :destroy-on-close="true"
  >
    <div class="purchaseModalMain">
      <div class="mainHeader">
        <div class="userInfo">
          <img src="./assets/img/user_avatar.png" alt="" class="avatar" />
          <div class="userInfoRight">
            <div class="userId">ID: {{ userInfo?.uid }}</div>
            <div class="tag" v-if="!isVip">普通用户</div>
            <div class="vipTag" v-else-if="isTimeVIP || isCfVIP">VIP会员</div>
          </div>
        </div>
        <i class="closeIcon el-icon-close" @click="handleCancel"></i>
      </div>
      <div class="purchaseModalContainer">
        <div class="modalContainerLeft">
          <div class="titleBox">
            <div class="title"><span>会员专属权益</span></div>
            <div class="subtitle">覆盖全站所有功能</div>
          </div>
          <ul class="entitlementTable">
            <li>
              <span class="icon"></span>
              批量压缩文件
            </li>
            <li>
              <span class="icon"></span>
              不限次数使用
            </li>
            <li>
              <span class="icon"></span>
              自由设置图片参数
            </li>
            <li>
              <span class="icon"></span>
              支持5+格式图片压缩
            </li>
            <li>
              <span class="icon"></span>
              支持10+格式图片转换
            </li>
            <li>
              <span class="icon"></span>
              支持PDF格式转换
            </li>
            <li>
              <span class="icon"></span>
              专属人工服务
            </li>
            <li>
              <span class="icon"></span>
              新增功能免费使用
            </li>
            <li>
              <span class="icon"></span>
              提供正规发票
            </li>
          </ul>
        </div>
        <div class="modalContainerRight">
          <div class="packageList">
            <div
              class="packageItem"
              @click="selectPackage(item)"
              :class="{ packageItem: true, active: checkID === item.id, activity: !!item.activity }"
              v-for="item in packages"
              :key="item.id"
            >
              <img class="cornerImg" v-if="item.cornerImg" :src="item.cornerImg"/>
              <div class="packageItemContainer" v-if="!!item.activity">
                <div class="packageItemTopWrap">
                  <div class="packageItemTop">
                    <div class="packageTitle">{{ item.title }}</div>
                    <div class="desc">
                      {{item.description}}
                      <img src="@/assets/img/xianshi.svg" alt="" class="pic">
                    </div>
                    <div class="featureText" v-if="item.feature">{{item.feature}}</div>
                  </div>
                </div>
                <div class="discountContainer">
                  <div class="p1">
                    <div class="p_title">日常价</div>
                    <div class="p_box">
                      <span class="p_unit">¥</span>
                      <span>{{item.originPrice}}</span>
                    </div>
                  </div>
                  <div class="p1">
                    <div class="p_title" style="margin-left: 5px;">折扣减</div>
                    <div class="p_box">
                      <span style="margin-right: 4px;">-</span>
                      <span class="p_unit">¥</span>
                      <span>{{item.discountPriceNum}}</span>
                    </div>
                  </div>
                  <div class="activityPriceBox">
                    <span class="p_title">活动价</span>
                    <div class="p_box">
                      <span class="p_unit">¥</span>
                      <span class="p_price">{{item.price}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="packageItemContainer" v-else>
                <div class="packageItemTopWrap">
                  <div class="packageItemTop">
                    <div class="packageTitle">{{ item.title }}</div>
                    <div class="priceBox">
                      <div class="currentPrice">
                        <span v-if="item.divisor">≈</span>￥<span class="price">{{ item.unitPrice }}</span>
<!--                        <img src="@/assets/img/xianshi.svg" alt="" class="xianshi">-->
                        <span class="unit" v-if="item.unit">{{ item.unit }}</span>
                      </div>
                      <div class="originPrice" v-if="item.originPrice">{{ item.originPrice }}</div>
                    </div>
                  </div>
                </div>

                <div class="packageDesc">{{ item.description }}</div>
              </div>
            </div>
          </div>

          <div class="payInfoBox">
            <div class="qrCodeBox">
              <img :src="qrCodeSrc" alt="" class="qrCodeImg" v-if="!loading" />
              <div class="loading" v-else>
                <img src="@/assets/img/loading.png" alt="" class="loadingImg" />
              </div>
            </div>
            <div class="payInfo">
              <div class="info">
                <span>实付金额：</span>
                <span class="price">￥{{ price }}</span>
                <div class="discounts" v-if="discountPrice">{{ discountPrice }}</div>
                <div v-if="showCountDown" class="timeBox">
                  <div>优惠仅剩</div>
                  <div class="number">00</div>
                  <span class="symbol">:</span>
                  <div class="number">{{remainingTime.minutes < 10 ? '0' + remainingTime.minutes : remainingTime.minutes}}</div>
                  <span class="symbol">:</span>
                  <div class="number">{{remainingTime.seconds  < 10 ? '0' + remainingTime.seconds : remainingTime.seconds}}.{{remainingTime.milliseconds < 10 ? '0' + remainingTime.milliseconds : remainingTime.milliseconds}}</div>
                </div>
                <div class="payInfoTips" v-else>购买后可联系客服开具发票</div>
              </div>
              <div class="channelTips">
                <span class="wxPay"></span>
                <span class="aliPay"></span>
                <span>请使用微信/支付宝扫码支付</span>
              </div>
              <div class="payUserInfo">
                <ul class="payUserList" style="height: 48px">
                  <li>用户139****7468 12分钟前购买了 终身会员</li>
                  <li>用户136****8012 10分钟前购买了 终身会员</li>
                  <li>用户156****9735 12分钟前购买了 终身会员</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tipsBox">
            <div class="tipsTitle">购买须知</div>
            <p class="tipsItem"><span class="blue">没有自动续费</span>，请放心购买</p>
            <p class="tipsItem">按张付费购买下载次数 <span class="blue">永久有效</span></p>
            <p class="tipsItem">开通任一会员，会员有效期内所有功能 <span class="blue">不限使用、不限张数</span></p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import QRCode from 'qrcode'
import { createOrder, getPayStatus } from '@/api'
import { Message } from 'element-ui'
import { trackOrder } from '@/utils/track'
import { packageList, sougou_packageList } from '@/config'

export default {
  name: 'PurchaseModal',
  components: {},
  data() {
    return {
      loading: false,
      checkIndex: 0,
      purchaseList: [],
      qrCodeSrc: '',
      pollCount: 0, // 轮询次数
      payResultTimer: null,
      checkID: 0,
      price: 0,
      discountPrice: 0,
      visible: false,
      onCancel: () => {},
      afterClose: () => {},
      onPaySuccess: () => {},
      packageList: packageList,
      souGouPackageList: sougou_packageList,
      startTime: new Date().getTime(),
      expiredTime: 1 * 60 * 1000, // 过期时间，默认1分钟
      timer: null, // 存储计时器实例
      remainingTime: {
        minutes: 10,
        seconds: 59,
        milliseconds: 59
      }, // 剩余时间显示，包括毫秒
      showCountDown: false, // 是否显示倒计时
    }
  },
  computed: {
    ...mapState({
      allCert: state => state.allCert
    }),
    ...mapGetters(['getUserInfo', 'isTimeVIP', 'isCountVIP', 'isVip', 'isCfVIP', 'isThreeDayVIP']),

    userInfo() {
      return this.getUserInfo
    },
    packages() {
      let data = this.packageList
      if (this.souGouPackageList?.length) {
        let inSougou = !!localStorage.getItem('sougou_tag')
        if (inSougou) {
          data = this.souGouPackageList
        }

      }
      // 筛选出符合当前用户等级的套餐
      return data.filter(item => item.level.includes(this.allCert?.vip))
    }
  },
  methods: {
    ...mapActions(['updateAllCert']),
    countDown(id) {
      const time = 5 * 60 * 1000; // 倒计时时长（5分钟）

      // 获取当前时间和结束时间
      const currentTime = new Date().getTime()
      let currentDate = localStorage.getItem('E_TIME')
      let endTime = currentTime + time;

      if (currentDate) {
        let d = JSON.parse(currentDate)
        if (typeof d !== 'object' || d === null) {
          d = {}; // 如果解析结果不是对象，则重置为对象
        }
        if (d[`t_${id}`]) {
          endTime =  d[`t_${id}`]
        } else {
          d[`t_${id}`] = currentTime + time
          localStorage.setItem('E_TIME', JSON.stringify(d));
        }

      } else {
        // 保存结束时间到 localStorage
        let d = {}
        d[`t_${id}`] = endTime
        localStorage.setItem('E_TIME', JSON.stringify(d));
      }

      // 清除之前的计时器（防止多次启动）
      if (this.timer) {
        clearInterval(this.timer);
      }

      // 启动倒计时
      this.timer = setInterval(() => {
        const remainingTime = endTime - new Date().getTime();

        if (remainingTime <= 0) {
          // 更新结束时间并保存到 localStorage
          endTime = new Date().getTime() + time;
          localStorage.setItem('E_TIME', endTime);
          // 重新开始倒计时
          this.countDown();
        } else {
          // 更新界面上的倒计时（例如显示剩余时间）
          this.remainingTime = this.formatTime(remainingTime);
        }
      }, 10);
    },

    // 格式化时间显示（可选）
    formatTime(ms) {
      const minutes = Math.floor(ms / (1000 * 60));
      const seconds = Math.floor((ms % (1000 * 60)) / 1000);
      const milliseconds = Math.floor((ms % 1000) / 10); // 取两位毫秒
      return {
        minutes: minutes,
        seconds,
        milliseconds
      }
    },

    // 停止倒计时
    stopCountDown() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    /**
     * @description 转换二维码
     * @param path 路径信息
     * @returns {Promise<unknown>}
     */
    async toQRCode(path) {
      return QRCode.toDataURL(path)
    },
    /**
     * @description 轮询，获取支付状态
     * @param orderId 订单ID
     */
    lookup(orderId) {
      clearTimeout(this.payResultTimer)

      // if (this.pollCount > 5000) {
      //   // 支付超时
      //   this.hide()
      //   return
      // }
      // 判断二维码是否过期
      if (new Date().getTime() - this.startTime >= this.expiredTime) {
        this.checkPurchase(this.checkID)
        return
      }

      this.payResultTimer = setTimeout(async () => {
        let r = await getPayStatus(orderId)
        if (r.data.status === 0 && r.data.data.order.status === 1) {
          // 查询成功 并且 状态为1 或者支付超时
          await this.$store.dispatch('updateUserInfo')
          Message({
            type: 'success',
            message: '支付成功'
          })
          trackOrder(this.checkID, orderId, r.data.data.money.cash_total)
          this.onPaySuccess()
          this.hide()
        } else {
          this.lookup(orderId)
        }
      }, 1500)

      this.pollCount++ // 增加轮询次数
    },
    selectPackage(item) {
      this.showCountDown = !!item?.showCountDown
      if (item?.showCountDown) {
        this.countDown(item.id)
      }
      this.checkPurchase(item.id)
    },
    /**
     * @description 切换套餐
     * @param idx 选中的索引
     * @returns {Promise<void>}
     */
    async checkPurchase(id) {
      this.loading = true
      this.checkID = id
      let target = this.packages.find(item => item.id === id)
      this.price = target?.price
      this.discountPrice = target?.discountPrice
      let res = await createOrder(id)
      this.startTime = new Date().getTime()
      if (res.data.status === 0) {
        let src = await this.toQRCode(res.data.data)
        this.qrCodeSrc = src
        this.loading = false
        this.pollCount = 0 // 清空轮询次数
        let s = res.data.data.split('/')
        let orderId = s[s.length - 1]
        this.lookup(orderId)
      } else {
        // 二维码获取失败
      }
    },
    /**
     * 取消
     */
    handleCancel() {
      this.onCancel()
      this.hide()
    },
    /**
     * @description 关闭窗口
     */
    handleClose() {
      this.afterClose()
    },
    hide() {
      clearInterval(this.payResultTimer)
      this.visible = false
    },

    /**
     * @description 打开窗口
     */
    async onOpenDialog() {
      // 默认选择当前用户等级推荐的
      let target = this.packages.find(item => item.defaultChecked)
      target = target ? target : this.packages[0]
      this.selectPackage(target)
      // await this.checkPurchase(target?.id)
    }
  }
}
</script>
<style scoped lang="less">
@import './main.less';
</style>
