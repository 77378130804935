import { VIP_LEVEL } from '@/config'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import {batchDownload, batchDownloadZip, changeSizeUnit, singleDownload} from '@/utils'
import { getToken } from '@/utils/token'
import { duce } from '@/api'
import {trackUsageFunc} from "@/utils/track";

export const mixins = {
  computed: {
    ...mapState({
      $_mixin_allCert: state => state.allCert
    })
  },
  methods: {
    /**
     * 打开套餐弹窗
     * @param options
     */
    $_mixin_showPurchaseModal(options) {
      let that = this;
      this.$PurchaseModal({
        ...options,
        onPaySuccess: (data) => {
          that.$store.dispatch('setIsShowCollecting', true)
          setTimeout(() => {
            that.$store.dispatch('setIsShowCollecting', false)
          }, 5000)
        },
        afterClose: () => {
          if (that.$_mixin_allCert?.vip === VIP_LEVEL.NON_VIP || that.$_mixin_allCert?.vip === VIP_LEVEL.COUNT_VIP) {

            that.$RetentionModal({
                onPaySuccess(data) {
                  that.$store.dispatch('setIsShowCollecting', true)
                  setTimeout(() => {
                    that.$store.dispatch('setIsShowCollecting', false)
                  }, 5000)
                },
                afterClose() {
                  that.$store.dispatch('updateUserInfo')
                }
              })

          }
          if (typeof options?.afterClose === 'function') {
            options.afterClose()
          }

          this.$store.dispatch('updateUserInfo')
        }
      })
    },
    /**
     * 上传文件校验
     * @param file File对象
     * @param formats 格式列表，例如：['.png','.jpg',...]
     * @param maxSize 单位：Byte
     * @returns {boolean}
     */
    $_mixin_checkUploadFile(file, { formats, maxSize }) {
      let _formats = formats.map(item => item.toLowerCase())
      let suffix = file.name.slice(file.name.lastIndexOf('.'))
      if (!_formats.includes(suffix)) {
        Message({
          type: 'error',
          message: '请上传指定的格式！'
        })
        return false
      }
      if (file.size > maxSize) {
        this.$Msg({
          showLeft: false,
          rightBtnTxt: '知道了',
          content: `单个文件大小不得超过${changeSizeUnit(maxSize)}`
        })
        return false
      }
      return true
    },
    /**
     * 下载校验
     * @param fileList
     * @returns {Promise<boolean>}
     */
    async $_mixin_checkAllowDownload(fileList) {
      if (!getToken()) {
        this.$Login()
        return false
      }

      trackUsageFunc(this.$route.name, this.$route.meta?.pageTitle)
      // 过滤出来未下载过的文件
      let downloadList = fileList.filter(file => !file.isDownload)

      // 能够直接下载的VIP等级
      let isDirectDownload =
        this.$_mixin_allCert?.vip !== VIP_LEVEL.NON_VIP && this.$_mixin_allCert?.vip !== VIP_LEVEL.COUNT_VIP

      if (downloadList.length === 0 || isDirectDownload) {
        return true
      }

      // 次数VIP能下载
      const canDownloadWithCountVIP =
        this.$_mixin_allCert?.vip === VIP_LEVEL.COUNT_VIP &&
        this.$_mixin_allCert?.has_image_count >= downloadList.length

      // 如果是次数VIP 并且 扣除权益成功
      if (canDownloadWithCountVIP) {
        let res = await duce(downloadList.length)
        if (res.data.status === 0) {
          await this.$store.dispatch('updateUserInfo')
          return true
        }
      }
      this.$_mixin_showPurchaseModal()
      return false
    },
    /**
     * 下载函数
     * @param downloadFiles
     * @returns {Promise<*>}
     */
    async $_mixin_download(downloadFiles, zip=false) {
      // 单张下载
      if (downloadFiles.length === 1) {
        await singleDownload(downloadFiles)
      }
      // 多张下载
      if (downloadFiles.length > 1) {
        if (zip) {
          await batchDownloadZip(downloadFiles)
        } else {
          await batchDownload(downloadFiles)
        }

      }

      return downloadFiles.map(item => {
        item.isDownload = true
        return item
      })
    }
  }
}
