<template>
  <el-dialog
    class="purchaseBox"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="480px"
    :append-to-body="true"
    :show-close="false"
    :custom-class="'purchaseWrapper'"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    @open="onOpenDialog"
    @closed="handleClose"
    :destroy-on-close="true"
  >
    <div class="purchaseModalMain">
      <i class="closeIcon el-icon-close" @click="onClickClose"></i>
      <img src="./assets/img/retentionPopBg.png" alt="" class="popBg" />
      <div class="countDownBox">
        <span class="countDownTitle">优惠仅剩：</span>
        <div class="timeBox">
          <div class="time">
            <span>{{ formattedTime.hours }}</span>
          </div>
          <span class="semicolon">:</span>
          <div class="time">
            <span>{{ formattedTime.minutes }}</span>
          </div>
          <span class="semicolon">:</span>
          <div class="time">
            <span>{{ formattedTime.seconds }}</span>
          </div>
          <span class="semicolon">:</span>
          <div class="time">
            <span>{{ formattedTime.milliseconds }}</span>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="packageList">
          <div
            class="packageItem"
            @click="checkPurchase(item.id)"
            :class="{ packageItem: true, active: checkId === item.id }"
            v-for="item in packages"
            :key="item.id"
          >
            <div class="corner" v-if="item.corner">{{ item.corner }}</div>
            <div class="packageItemContainer">
              <div class="packageTitle">{{ item.title }}</div>
              <div class="priceBox">
                <div class="currentPrice">
                  ￥<span class="price_1">{{ item.unitPrice }}</span>
                </div>
                <div class="originPrice_1" v-if="item.originPrice">¥{{ item.originPrice }}</div>
              </div>
              <div class="packageDesc">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>

      <span class="price">
        <span class="unit">¥</span>
        <span>{{ price }}</span>
      </span>

      <div class="payTitle">扫码立即开通</div>
      <div class="payChannelBox">
        <span class="wechat_icon"></span>
        <span class="ali_icon"></span>
        <span>扫码支付</span>
      </div>
      <div class="qrCodeBox">
        <img :src="qrCodeSrc" alt="" class="qrCodeImg" v-if="!loading" />
        <div class="loading" v-else>
          <img src="./assets/img/loading.png" alt="" class="loadingImg" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import QRCode from 'qrcode'
import { createOrder, getPayStatus } from '@/api'
import { Message, MessageBox } from 'element-ui'
import { trackOrder } from '@/utils/track'
import {retentionList, sougou_retentionList, retentionList2, VIP_LEVEL} from '@/config'

export default {
  name: 'RetentionModal',
  components: {},
  data() {
    return {
      loading: false,
      qrCodeSrc: '',
      price: 0,
      pollCount: 0, // 轮询次数
      payResultTimer: null,
      titleTxt: '', //
      purchaseTitle: '',
      checkId: '',
      originPrice: '',
      startTime: null,
      visible: false,
      retentionList: retentionList,
      souGouRetentionList: sougou_retentionList,
      retentionList2: retentionList2,
      remainingTime: 3600000, // 1 hour in milliseconds
      onCancel: () => {},
      afterClose: () => {},
      onPaySuccess: () => {},
      beginTime: new Date().getTime(),
      expiredTime: 1 * 60 * 1000,
      packages: []
    }
  },
  computed: {
    ...mapState({
      allCert: state => state.allCert
    }),
    formattedTime() {
      const hours = Math.floor(this.remainingTime / 3600000)
      const minutes = Math.floor((this.remainingTime % 3600000) / 60000)
      const seconds = Math.floor((this.remainingTime % 60000) / 1000)
      const milliseconds = Math.floor((this.remainingTime % 1000) / 10)
      return {
        hours: this.pad(hours),
        minutes: this.pad(minutes),
        seconds: this.pad(seconds),
        milliseconds: this.padMilliseconds(milliseconds)
      }
    },

  },
  methods: {
    ...mapActions(['updateAllCert', 'hideRetentionModal']),
    getPackages() {
        let data = this.retentionList
        if (this.allCert?.vip === VIP_LEVEL.NON_VIP) {
          const count = localStorage.getItem('_APP_COUNT') ?? 1
          data = Number(count) > 1 ?  data : this.retentionList2
          localStorage.setItem('_APP_COUNT', Number(count) + 1)
        }
        if (this.souGouRetentionList.length) {
          let inSougou = !!localStorage.getItem('sougou_tag')
          if (inSougou) {
            data = this.souGouRetentionList
          }
        }
        return data.filter(item => item.level.includes(this.allCert?.vip))

    },
    /**
     * @description 转换二维码
     * @param path 路径信息
     * @returns {Promise<unknown>}
     */
    async toQRCode(path) {
      return QRCode.toDataURL(path)
    },
    /**
     * @description 轮询，获取支付状态
     * @param orderId 订单ID
     */
    lookup(orderId) {
      clearTimeout(this.payResultTimer)

      // if (this.pollCount > 5000) {
      //   // 支付超时
      //   this.hideRetentionModal()
      //   return
      // }
      if (new Date().getTime() - this.beginTime >= this.expiredTime) {
        this.checkPurchase(this.checkId)
        return
      }

      this.payResultTimer = setTimeout(async () => {
        let r = await getPayStatus(orderId)
        if (r.data.status === 0 && r.data.data.order.status === 1) {
          // 查询成功 并且 状态为1 或者支付超时
          // await this.updateAllCert(r.data.data.money) // 更新用户权益
          await this.$store.dispatch('updateUserInfo')
          this.onPaySuccess()
          Message({
            type: 'success',
            message: '支付成功'
          })
          clearTimeout(this.payResultTimer)
          trackOrder(this.id, orderId, r.data.data.money.cash_total)
          this.hide()
        } else {
          this.lookup(orderId)
        }
      }, 1500)

      this.pollCount++ // 增加轮询次数
    },
    /**
     * @description 切换套餐
     * @param idx 选中的索引
     * @returns {Promise<void>}
     */
    async checkPurchase(id) {
      let target = this.packages.find(item => item.id === id)
      this.loading = true
      this.price = target.price
      this.checkId = id
      let res = await createOrder(id)
      if (res.data.status === 0) {
        this.beginTime = new Date().getTime()
        let src = await this.toQRCode(res.data.data)
        this.qrCodeSrc = src
        this.loading = false
        this.pollCount = 0 // 清空轮询次数
        let s = res.data.data.split('/')
        let orderId = s[s.length - 1]
        this.lookup(orderId)
      } else {
        // 二维码获取失败
      }
    },
    /**
     * @description 关闭窗口
     */
    handleClose() {
      this.afterClose()
    },
    hide() {
      clearInterval(this.payResultTimer)
      clearInterval(this.timer)
      this.visible = false
    },
    onClickClose() {
      this.onCancel()
      MessageBox.confirm('您目前拥有优惠购买特权，退出后将无法享用?', '温馨提示', {
        confirmButtonText: '继续支付',
        cancelButtonText: '确定放弃',
        customClass: 'my_dialog',
        type: 'warning',
        showClose: false
      }).catch(() => {
        this.hide()
      })
    },
    /**
     * @description 打开窗口
     */
    async onOpenDialog() {
      this.packages = this.getPackages()
      let target = this.packages.find(item => item.defaultChecked)
      target = target ? target : this.packages[0]
      await this.checkPurchase(target?.id)

      const savedStartTime = localStorage.getItem('countdownStartTime')
      if (savedStartTime) {
        this.startTime = parseInt(savedStartTime, 10)
      } else {
        this.startTime = Date.now()
        localStorage.setItem('countdownStartTime', this.startTime)
      }
      this.startCountdown()
    },
    pad(number) {
      return number < 10 ? '0' + number : number
    },
    padMilliseconds(number) {
      if (number < 10) {
        return '0' + number
      } else if (number < 100) {
        return number
      }
      return number
    },
    startCountdown() {
      const now = Date.now()
      const elapsed = now - this.startTime
      this.remainingTime = 5 * 60 * 1000 - elapsed
      if (this.remainingTime <= 0) {
        this.resetCountdown()
      } else {
        this.countdown()
      }
    },
    countdown() {
      this.timer = setInterval(() => {
        this.remainingTime -= 10
        if (this.remainingTime <= 0) {
          this.resetCountdown()
        }
      }, 10)
    },
    resetCountdown() {
      clearInterval(this.timer)
      this.startTime = Date.now()
      localStorage.setItem('countdownStartTime', this.startTime)
      this.remainingTime = 5 * 60 * 1000
      this.countdown()
    }
  }
}
</script>
<style scoped lang="less">
@import './main.less';
</style>
