<template>
  <header class="header_wrapper">
    <div class="header_container">
      <div class="header_left">
        <div>
          <el-popover placement="bottom-start" width="300" :value="isShowCollecting" popper-class="popover" trigger="hover" v-if="getAppRuntime?.platform !== 'electron'">
            <div v-if="isChrome">
              <p class="title">你可以通过以下方式收藏轻秒网址，再也不担心找不到啦～</p>
              <ul>
                <li>1、按{{ isMacOs ? `「⌘+D」` : `「Ctrl+D」` }}收藏本站 或 拖动LOGO到书签栏</li>
                <li>2、点击 <span class="line" @click="createFast">创建快捷方式→</span></li>
              </ul>
            </div>
            <div v-else>
              <p>按{{ isMacOs ? `「⌘+D」` : `「Ctrl+D」` }}收藏本站 或 拖动LOGO到书签栏</p>
            </div>
            <router-link to="/" slot="reference">
              <div :class="`logo_wrapper ${getAppRuntime?.platform !== 'electron' && appConfig?.allowDownloadDesktop ? 'hasAllowDownloadDesktop' : ''}`">
                <img src="@/assets/img/logo.svg" alt="轻秒图片转换器" class="logo" />
                <img :src="getAppRuntime?.platform !== 'electron' ? appConfig?.logo : 'https://res.yunkun.cn/img_geshicn/img/logo_desktop.svg'" alt="轻秒图片转换器" class="logo1" />
              </div>
            </router-link>
          </el-popover>
          <router-link to="/" v-else>
            <div :class="`logo_wrapper ${getAppRuntime?.platform !== 'electron' && appConfig?.allowDownloadDesktop ? 'hasAllowDownloadDesktop' : ''}`">
              <img src="@/assets/img/logo.svg" alt="轻秒图片转换器" class="logo" />
              <img :src="getAppRuntime?.platform !== 'electron' ? appConfig?.logo : 'https://res.yunkun.cn/img_geshicn/img/logo_desktop.svg'" alt="轻秒图片转换器" class="logo1" />
            </div>
          </router-link>
        </div>
        <NavMenu :menus="menus" />
      </div>
      <div class="header_right" id="custom-container">
        <div v-if="getAppRuntime?.platform !== 'electron' && appConfig?.allowDownloadDesktop">
          <el-popover
                  :append-to-body="false"
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  v-if="isDownload"
          >
            <div>
              <span>下载进度：</span>
              <el-progress :percentage="downloadProgress"></el-progress>
            </div>
            <div class="downloadDesktopBtn" slot="reference">
              <img src="@/assets/img/desktop.svg" alt="" class="icon">
              <span>客户端</span>
            </div>
          </el-popover
                  >
          <el-popover
                  placement="top"
                  title=""
                  width="200"
                  trigger="hover"
                  popper-class="customClass"
                  :append-to-body="false"
                  v-else-if="appConfig?.allowDownloadAPP"
          >
            <div>
              <div class="download_btn" @click="downloadDesktop">
                下载Win版客户端
              </div>
              <el-popover
                      placement="right-start"
                      title=""
                      width="100"
                      trigger="hover"
                      :visible-arrow="false"
                      :append-to-body="false"
              >
                <div class="qrCodeBox">
                  <img :src="appConfig.androidDownload" alt="" class="qrCode" />
                  <div class="boxTitle">下载Android App</div>
                </div>
              <div class="download_btn" slot="reference">
                下载Android App
              </div>
              </el-popover>
              <el-popover
                      placement="right-start"
                      title=""
                      width="100"
                      trigger="hover"
                      :visible-arrow="false"
                      :append-to-body="false"
              >
                <div class="qrCodeBox">
                  <img :src="appConfig.iosDownload" alt="" class="qrCode" />
                  <div class="boxTitle">下载iOS App</div>
                </div>
                <div class="download_btn" slot="reference">
                  下载iOS App
                </div>
              </el-popover>
            </div>
            <div class="downloadDesktopBtn" slot="reference">
              <img src="@/assets/img/desktop.svg" alt="" class="icon">
              <span>客户端</span>
            </div>
          </el-popover
          >
          <div class="downloadDesktopBtn" @click="downloadDesktop" v-else>
            <img src="@/assets/img/desktop.svg" alt="" class="icon">
            <span>客户端</span>
          </div>
        </div>


        <PurchaseButton
          v-if="allCert?.vip === VIP_LEVEL.COUNT_VIP || allCert?.vip === VIP_LEVEL.YEAR_VIP"
          class="purchaseButtonComponent"
          :width="'135px'"
          :height="'44px'"
          :border-radius="'28px'"
          >限时升级 <span class="corner">终身仅39元</span></PurchaseButton
        >
        <PurchaseButton
          v-else-if="!isCountVIP && !isCfVIP && allCert?.vip !== VIP_LEVEL.YEAR_VIP"
          class="purchaseButtonComponent"
          :width="'120px'"
          :height="'44px'"
          :border-radius="'28px'"
          >{{ btnTxt }} <img src="https://res.yunkun.cn/img_geshicn/img/activity.svg" class="activity_img" alt=""
        /></PurchaseButton>
        <div v-if="!isLogin" class="loginWrap">
          <LoginButton>登录/注册<img src="@/assets/img/login_tips.svg" class="tips" alt="" /></LoginButton>
        </div>
        <UserInfo v-else />
      </div>
    </div>
  </header>
</template>

<script>
import LoginButton from '@/components/LoginButton/index.vue'
import PurchaseButton from '@/components/PurchaseButton/index.vue'
import NavMenu from '@/components/NavMenu/index.vue'
import UserInfo from '@/components/UserInfo/index.vue'
import { headerMenus } from '../../config/menus.config'
import { mapGetters, mapState } from 'vuex'
import { isMacOS } from '@/utils/isMacOS'
import { isChrome } from '@/utils/isChrome'
import {VIP_LEVEL, appConfig} from "@/config";
import {getToken} from "@/utils/token";
import axios from "axios";
import {saveAs} from 'file-saver'

export default {
  name: 'Header',
  components: {
    UserInfo,
    NavMenu,
    LoginButton,
    PurchaseButton
  },
  props: {},
  data() {
    return {
      menus: headerMenus,
      deferredPrompt: null,
      listenFunc: () => {},
      isChrome: isChrome(),
      isMacOs: isMacOS(),
      VIP_LEVEL,
      appConfig,
      getAppRuntime: null,
      isDownload: false,
      downloadProgress: 0
    }
  },
  computed: {
    ...mapGetters(['isVip', 'isTimeVIP', 'isCountVIP', 'isCfVIP', 'isLogin']),
    ...mapState({
      userInfo: state => state.userInfo,
      allCert: state => state.allCert,
      isShowCollecting: state => state.isShowCollecting
    }),
    btnTxt() {
      if (this.isCountVIP || this.isTimeVIP) {
        return '续费会员'
      }
      return '开通会员'
    }
  },
  methods: {
    createFast() {
      // this.deferredPrompt?.prompt()
      this.$store.dispatch('installApp')
    },
    onMouseEnter() {
      this.$store.dispatch('setIsShowCollecting', true)
    },
    onMouseLeave() {
      this.$store.dispatch('setIsShowCollecting', false)
    },
    async downloadDesktop(){
      if (this.isDownload) {
        return
      }
      this.isDownload = true
      let downloadLink = 'https://res.yunkun.cn/softdownload/qm_img_install.exe'
      let that = this
      let res = await axios.get(downloadLink, {
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {//axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
          if (progressEvent.lengthComputable) {
            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
            //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
            that.downloadProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100) //实时获取最新下载进度
            that.isDownload = true
          }
        },
      })
      let blob = new Blob([res.data])
      let bd_vid = this.$route.query?.bd_vid ?? window.localStorage.getItem('bd_vid')
      saveAs(blob, `qm_s=${bd_vid ? 'bd_'+ bd_vid + '_t_' + new Date().getTime() : 'qm_img_install'}.exe`)
      this.isDownload = false
    }
  },
  mounted() {
    let value = window.localStorage.getItem('collecting')
    let temp = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
    // 没有值
    if ((!value || new Date().getTime() - value >= 24 * 60 * 60 * 1000) && getToken() && this.$store.state.allCert?.vip !== VIP_LEVEL.NON_VIP) {
      this.$store.dispatch('setIsShowCollecting', true)
      setTimeout(() => {
        window.localStorage.setItem('collecting', `${temp}`)
        this.$store.dispatch('setIsShowCollecting', false)
      }, 5000)
    }

    this.getAppRuntime = window?.getAppRuntime
  }
}
</script>

<style scoped lang="less">
@import url('./index.less');
</style>
