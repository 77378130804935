export * from './app'

export const COMPRESS_UPLOAD_CONFIG = {
  MAX_SIZE: 100 * 1024 * 1024, // 单张最大上传大小，单位：b
  VIP_MAX_SIZE: 100 * 1024 * 1024, // 会员最大上传大小，单位b
  COUNTS: 30, // 默认上传数量
  DPI: [
    {
      title: '72dpi',
      value: 72
    },
    {
      title: '96dpi',
      value: 96
    },
    {
      title: '150dpi',
      value: 150
    },
    {
      title: '200dpi',
      value: 200
    },
    {
      title: '300dpi',
      value: 300
    },
    {
      title: '320dpi',
      value: 320
    },
    {
      title: '350dpi',
      value: 350
    },
    {
      title: '500dpi',
      value: 500
    },
    {
      title: '1000dpi',
      value: 1000
    }
  ], // 支持的dpi
  OUTPUT_FORMAT: [
    {
      title: '格式不变',
      value: 'none'
    },
    {
      title: 'JPG格式',
      value: 'jpg'
    },
    {
      title: 'PNG格式',
      value: 'png'
    }
  ],
  /* 压缩设置 */
  COMPRESS_SETTINGS: {
    MINI: 0.3, // 缩小优先，调节的压缩率, 取值范围：0 - 1，数值越大压缩率就越小
    CLEAR: 0.7 // 清晰优先，调节的压缩率, 取值范围：0 - 1，数值越大压缩率就越小
  }
}
export const EDIT_UPLOAD_CONFIG = {
  MAX_SIZE: 100 * 1024,
  COUNTS: 12
}

export const CONVERT_UPLOAD_CONFIG = {
  MAX_SIZE: 100 * 1024,
  COUNTS: 12
}




/**
 * 定义的用户当前模式
 */
export const MODE = {
  COMPRESS: {
    MINIMIZE: 'minimize',
    CLEAR: 'clear',
    SET_CLEAR_VALUE: 'clearValue',
    SET_SIZE: 'size'
  },
  EDIT: {
    RESIZE: {
      CONTAIN: 'contain',
      COVER: 'cover',
      NONE: 'none'
    },
    SET_WIDTH_HEIGHT: 'edit',
    SET_DPI: 'changeDpi'
  }
}
export const formatTypes = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  bmp: 'image/bmp',
  webp: 'image/webp',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  avif: 'image/avif',
  orf: 'image/ORF',
  svg: 'image/svg+xml',
  heic: 'image/heic'
}

export const FILE_TYPE = [
  {
    type: 'image/png',
    ext: 'png',
    alias: 'PNG'
  },
  {
    type: 'image/jpeg',
    ext: 'jpg',
    alias: 'JPG'
  },
  {
    type: 'image/jpeg',
    ext: 'jpeg',
    alias: 'JPEG'
  },
  {
    type: 'image/gif',
    ext: 'gif',
    alias: 'GIF'
  },
  {
    type: 'image/bmp',
    ext: 'bmp',
    alias: 'BMP'
  },
  {
    type: 'image/webp',
    ext: 'webp',
    alias: 'WEBP'
  },
  {
    type: 'image/tiff',
    ext: 'tif',
    alias: 'TIF'
  },
  {
    type: 'image/tiff',
    ext: 'tiff',
    alias: 'TIFF'
  },
  {
    type: 'avif.avif',
    ext: 'avif',
    alias: 'AVIF'
  },
  {
    type: 'image/heic',
    ext: 'heic',
    alias: 'HEIC'
  },
  {
    type: 'image/svg+xml',
    ext: 'svg',
    alias: 'SVG'
  },
  {
    type: 'application/pdf',
    ext: 'pdf',
    alias: 'PDF'
  },
  {
    type: 'application/msword',
    ext: 'doc',
    alias: 'Word'
  },
  {
    type: 'application/wps-writer',
    ext: 'doc',
    alias: 'Word'
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ext: 'docx',
    alias: 'Word'
  },
  {
    type: 'application/vnd.ms-excel',
    ext: 'xls',
    alias: 'Excel'
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ext: 'xlsx',
    alias: 'Excel'
  },
  {
    type: 'application/vnd.ms-powerpoint',
    ext: 'ppt',
    alias: 'PPT'
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ext: 'pptx',
    alias: 'PPT'
  },
  {
    type: 'application/acad',
    ext: 'dwg',
    alias: 'CAD'
  },
  {
    type: 'application/x-acad',
    ext: 'dwg',
    alias: 'CAD'
  },
  {
    type: 'application/dxf',
    ext: 'dxf',
    alias: 'CAD'
  },
  {
    type: 'application/x-dxf',
    ext: 'dxf',
    alias: 'CAD'
  },
  {
    type: 'application/x-dwt',
    ext: 'dwt',
    alias: 'CAD'
  },
  {
    type: 'application/x-dws',
    ext: 'dws',
    alias: 'CAD'
  },
  {
    type: 'application/octet-stream',
    ext: 'dwt',
    alias: 'CAD'
  }
]

export const FILE_ICON = [
  {
    type: 'image/png',
    icon: require('../assets/img/icon/png.svg')
  },
  {
    type: 'image/jpeg',
    icon: require('../assets/img/icon/jpg.svg')
  },
  {
    type: 'image/jpeg',
    icon: require('../assets/img/icon/jpg.svg')
  },
  {
    type: 'application/pdf',
    icon: require('../assets/img/icon/pdf.svg')
  },
  {
    type: 'application/msword',
    icon: require('../assets/img/icon/word.svg')
  },
  {
    type: 'application/wps-writer',
    icon: require('../assets/img/icon/word.svg')
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: require('../assets/img/icon/word.svg')
  },
  {
    type: 'application/vnd.ms-excel',
    icon: require('../assets/img/icon/excel.svg')
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: require('../assets/img/icon/excel.svg')
  },
  {
    type: 'application/vnd.ms-powerpoint',
    icon: require('../assets/img/icon/ppt.svg')
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    icon: require('../assets/img/icon/ppt.svg')
  },
  {
    type: 'application/octet-stream',
    icon: require('../assets/img/icon/cad.svg')
  }
]
export * from './app'
export * from './package'
export * from './editor-photo'